import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DiscordIcon from './discord.png';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const App = () => {
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Container
                maxWidth="sm"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h2" align="center" sx={{ mb: 2 }}>
                    Coming Soon 😏
                </Typography>
                <Typography variant="body1" align="center" sx={{ mb: 4 }}>
                    We're working on something awesome! Stay tuned.
                </Typography>
                <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mb: 2 }}>
                    Copyright © 2023 InfinityRP.pl
                </Typography>
                <div sx={{ position: 'fixed', bottom: '1rem', right: '1rem' }}>
                    <Button color="inherit">
                        <img
                            src={DiscordIcon} // Replace with the actual path to your Discord logo image
                            alt="Discord Logo"
                            style={{ width: '32px'}}
                        />
                        <Typography variant="body2" sx={{ color: 'text.secondary', display: 'inline-block', ml: 1 }}>
                            .ezi
                        </Typography>
                    </Button>
                </div>
            </Container>
        </ThemeProvider>
    );
};

export default App;
